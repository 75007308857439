import { useCallback } from 'react';
import mixpanel from 'mixpanel-browser';

import { useGetAuthUser, useGetPractice } from '@shared/data/practice/hooks';
import { Accessor, Practice } from '@shared/data/types';
import { usePracticeStore } from '@shared/stores/practiceStore';

export const usePracticeMixpanel = () => {
  const { data: user } = useGetAuthUser(Accessor.PRACTICE_MEMBER, { enabled: false });
  const { selectedPracticeId: practiceId } = usePracticeStore();
  const { data: practiceData } = useGetPractice(practiceId, Accessor.PRACTICE_MEMBER);

  const identify = useCallback((id: string, practice: Practice) => {
    if (!practice.isDemo) {
      mixpanel.identify(id);
      mixpanel.people.set({
        'Practice name': practice.name,
        'Practice ID': practice.id,
        'userType': 'practitioner',
      });
    }
  }, []);

  const trackPracticeEvent = useCallback(
    (name: string, props?: Record<string, unknown>) => {
      if (practiceData && !practiceData?.data?.isDemo) {
        mixpanel.track(name, {
          'Practice name': practiceData?.data.name,
          'Practice ID': practiceData?.data.id,
          'userType': 'practitioner',
          'trackingId': user?.data.id,
          ...props,
        });
      }
    },
    [practiceData, user?.data?.id],
  );

  const trackSignIn = useCallback(() => {
    mixpanel.track('Practice signed in', {
      userType: 'practitioner',
      practice: user?.data.practices.map((practice) => practice.name).join(', '),
      practiceId: user?.data.practices.map((practice) => practice.id).join(', '),
    });
  }, [user]);

  return {
    identify,
    trackPracticeEvent,
    trackSignIn,
  };
};
