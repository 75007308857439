import { memo, ReactNode } from 'react'
import { IconName } from '@library/components/atoms/Icon/utils'
import clsx from 'clsx'

import { AccordionItem } from '../AccordionItem'

const accordionWrapperClass = `
  w-full
  flex
  flex-col
`
export interface AccordionItemType {
  title: string
  // This should be renamed to content https://linear.app/practi/issue/CHE-452/rename-accordionitem-prop-answer-to-content
  answer: ReactNode
}

export interface AccordionIcon {
  close: IconName
  open: IconName
}
export interface AccordionProps {
  size?: 'medium' | 'large'
  items: AccordionItemType[]
  icons?: AccordionIcon
}

export const Accordion = memo(
  ({
    size = 'medium',
    items,
    icons = {
      open: 'chevron-down',
      close: 'chevron-up',
    },
  }: AccordionProps) => {
    return (
      <div className={clsx(accordionWrapperClass)}>
        {items &&
          items.length &&
          items.map((item, index) => <AccordionItem key={index} item={item} icons={icons} size={size} />)}
      </div>
    )
  },
)

Accordion.displayName = 'Accordion'
