import { ChangeEvent, InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import { Label } from '@library/components/molecules'
import clsx from 'clsx'

import {
  baseClasses,
  baseDotClasses,
  baseSliderClasses,
  disabledClasses,
  getDotSizeClasses,
  getDotSlideClass,
  getSliderHoverClasses,
  getSliderSizeClasses,
  getSliderStateClasses,
} from './utils'

export interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
  labelText?: string
  labelStrong?: boolean
  helperText?: string
  labelLeft?: boolean
  toggleSize: 'small' | 'medium' | 'large'
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  testId?: string
  className?: string
}

export const Toggle = ({
  toggleSize = 'medium',
  labelText,
  labelStrong = true,
  helperText,
  labelLeft,
  defaultChecked,
  disabled,
  testId,
  className,
  ...inputProps
}: ToggleProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isChecked, setIsChecked] = useState<boolean | undefined>(false)

  useEffect(() => {
    setIsChecked(inputProps.checked)
  }, [inputProps.checked])

  const handleOnChange = () => {
    setIsChecked((prev) => !prev)
  }
  return (
    <label
      data-testid={`label-${testId}`}
      className={clsx(
        className,
        labelLeft ? 'justify-between' : 'justify-start',
        'gap-4 max-w-[22.5rem] inline-flex items-start select-none',
      )}
    >
      {labelText && (
        <Label
          htmlFor={inputProps.id}
          size={toggleSize}
          labelText={labelText}
          helperText={helperText}
          labelStrong={labelStrong}
          appearance="secondary"
          className={clsx(labelLeft ? 'order-first' : 'order-last', 'cursor-pointer')}
        />
      )}
      <input
        ref={inputRef}
        role="toggle"
        type="checkbox"
        className="sr-only w-fit"
        onChange={handleOnChange}
        disabled={disabled}
        defaultChecked={isChecked}
        data-testid={testId}
        {...inputProps}
      />
      <span
        className={clsx(
          baseClasses,
          baseSliderClasses,
          getSliderSizeClasses(toggleSize),
          getSliderStateClasses(isChecked),
          getSliderHoverClasses(isChecked),
          disabled && disabledClasses,
          labelText && 'mt-0.5',
        )}
        data-testid={`toggle-slider`}
      >
        <span
          data-testid="toggle-dot"
          className={clsx(baseDotClasses, getDotSizeClasses(toggleSize), isChecked && getDotSlideClass(toggleSize))}
        ></span>
      </span>
    </label>
  )
}

Toggle.displayName = 'Toggle'
