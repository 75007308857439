import { memo, useState } from 'react'
import { Icon } from '@library/components/atoms'
import { Paragraph } from '@library/components/atoms/Paragraph/Paragraph'
import { AccordionIcon, AccordionItemType } from '@library/components/molecules/Accordion/'
import { getAccordionSpacingSizes, getAccordionTextSize } from '@library/components/molecules/AccordionItem/utils'
import clsx from 'clsx'
const accordionItemWrapperClass = `
  w-full
  flex
  flex-col
  items-start
  border-b
  border-borderNeutralSoft
`
const accordionItemHeaderClass = `
  flex
  items-center
  justify-between
  w-full
  cursor-pointer
  outline-0
`

export interface AccordionItemProps {
  size?: 'medium' | 'large'
  item: AccordionItemType
  icons?: AccordionIcon
}

export const AccordionItem = memo(
  ({
    size = 'medium',
    item,
    icons = {
      open: 'chevron-down',
      close: 'chevron-up',
    },
  }: AccordionItemProps) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleToggleAccordion = () => {
      setIsOpen((prev) => !prev)
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === ' ') {
        event.preventDefault()
      }
      if (!isOpen) {
        if (event.key === 'ArrowDown' || event.key === 'Enter' || event.key === ' ') {
          setIsOpen(true)
          event.preventDefault()
        }
      }

      if (isOpen) {
        if (event.key === 'ArrowUp' || event.key === 'Enter' || event.key === ' ') {
          setIsOpen(false)
          event.preventDefault()
        }
      }
    }

    return (
      <div className={clsx(accordionItemWrapperClass, getAccordionSpacingSizes(size))}>
        <button
          className={clsx(accordionItemHeaderClass)}
          role="button"
          aria-expanded={isOpen}
          aria-controls="accordion-panel"
          aria-labelledby="accordion-button"
          onKeyDown={handleKeyDown}
          onClick={handleToggleAccordion}
        >
          <Paragraph size={getAccordionTextSize(size)} className="!font-medium text-foregroundNeutralPrimary">
            {item.title}
          </Paragraph>
          <Icon color="text-foregroundNeutralQuaternary" size={size} iconName={isOpen ? icons.close : icons.open} />
        </button>
        {isOpen && (
          <div role="panel" id="accordion-panel" aria-labelledby="accordion-panel">
            {typeof item.answer === 'string' ? (
              <Paragraph className="!text-foregroundNeutralSecondary" size={getAccordionTextSize(size)}>
                {item.answer}
              </Paragraph>
            ) : (
              item.answer
            )}
          </div>
        )}
      </div>
    )
  },
)

AccordionItem.displayName = 'AccordionItem'
